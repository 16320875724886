import { Button, CloseButton, Divider, Flex, Grid, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { GeneralApi, PaymentTermApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { initGeneral } from "../../store/General";
import { message } from "../../utils/Message";
import HttpHandler from "../../utils/http-handler";
import { Form } from "../form";
import { SelectItemField } from "../form/select-item-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

type Props = {
  onCancel: () => void;
  title: string;
  subTitle: any;
  action: any[];
  payload?: any;
  reload?: any;
};

type IFormData = {
  configType: string;
  condition: string;
  confirmationDay: string | number | null | undefined;
  advancePercent: string | number | null | undefined;
};

const schema = yup.object({
  condition: yup.string().required("Заавал бөглөнө!").nullable(),
  configType: yup.string().required("Заавал бөглөнө!").nullable(),
  advancePercent: yup
    .number()
    .when("configType", (configType, schema) => {
      return typeof configType === "string" && configType === "CIA"
        ? schema.min(1, "Багадаа 1 байна").max(90, "Ихдээ 90 хоног байна").typeError("Заавал бөглөнө!").required("Заавал бөглөнө").nullable()
        : schema.notRequired().nullable();
    })
    .optional(),
  confirmationDay: yup
    .number()
    .when("configType", (configType, schema) => {
      return typeof configType === "string" && configType === "CIA"
        ? schema.min(1, "Багадаа 1 байна").max(90, "Ихдээ 90 хоног байна").typeError("Заавал бөглөнө!").required("Заавал бөглөнө").nullable()
        : schema.notRequired().nullable();
    })
    .when("configType", (configType, schema) => {
      return typeof configType === "string" && configType === "CBD"
        ? schema.min(1, "Багадаа 1 байна").max(90, "Ихдээ 90 хоног байна").typeError("Заавал бөглөнө!").required("Заавал бөглөнө").nullable()
        : schema.notRequired().nullable();
    })
    .optional(),
});

export function CodTermForm({ title, subTitle, onCancel, action, reload }: Props) {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { paymentTermConfigTypes = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    configType: undefined,
    condition: params.type!,
    paymentDay: undefined,
    advancePercent: undefined,
    confirmationDay: undefined,
    ...(action && action[0] === "update" ? action[1] : action && action[0] === "detail" ? action[1] : {}),
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await PaymentTermApi.create({
        configType: data.configType,
        condition: data.condition,
        confirmationDay: data.confirmationDay,
        advancePercent: data.configType === "CBD" ? null : data.advancePercent,
      });

      message.success("Амжилттай шинэ нөхцөл нэмлээ!");

      const res = await GeneralApi.init();
      dispatch(initGeneral(res));
      setLoading(false);
      reload();
      onCancel && onCancel();
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <Divider mb="sm" />
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={6}>
                <SelectItemField
                  searchable
                  name="configType"
                  required
                  disabled={action[0] === "detail"}
                  placeholder="Нөхцөл код / тайлбар"
                  label="Нөхцөл код / тайлбар"
                  clearable
                  onChange={(e) => {
                    setFieldValue("confirmationDay", undefined);
                    setFieldValue("advancePercent", undefined);
                  }}
                  options={
                    paymentTermConfigTypes
                      ?.filter((item: any) => item.condition === params.type)
                      .map((item: IReference) => {
                        return {
                          label: item?.text || "-",
                          value: item.code,
                        };
                      }) || []
                  }
                />
              </Grid.Col>

              {values?.configType === "CIA" && (
                <Grid.Col span={6}>
                  <TextField disabled={action[0] === "detail"} label="Хувь" name="advancePercent" placeholder={"Хувь"} type="number" required />
                </Grid.Col>
              )}
              <Grid.Col span={6}>
                <TextField disabled={action[0] === "detail"} label="Хоног" name="confirmationDay" placeholder={"Хоног"} type="number" required />
              </Grid.Col>
            </Grid>
            <Divider mt="md" />
            <Flex mt="md" justify="flex-end" gap={10}>
              <Button variant="outline" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              {action && action[0] !== "detail" && (
                <Button loading={loading} variant="filled" type="submit">
                  Хадгалах
                </Button>
              )}
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
