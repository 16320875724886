import { Popover, Text } from "@mantine/core";

type PopoverTextProps = React.PropsWithChildren<{
  withArrow?: boolean;
  position?: "top" | "left" | "bottom" | "right";
  maxLength?: number;
}>;

export function PopoverText({ children = "", withArrow = true, position = "top", maxLength = 18 }: PopoverTextProps) {
  const content = String(children);

  if (!content || content.length <= maxLength) {
    return (
      <Text size="sm" w="max-content">
        {content || "-"}
      </Text>
    );
  }

  return (
    <Popover position={position} withArrow={withArrow} shadow="sm">
      <Popover.Target>
        <Text component="a" w="max-content" size="sm" title={content}>
          {content.substring(0, maxLength)}...
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm" style={{ overflowWrap: "break-word" }}>
          {content}
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
}
