import { Button, Divider, Grid, Group, Input, Paper, Text } from "@mantine/core";
import { forwardRef, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { NetworkSetApi } from "../../apis";
import { AccountManageList } from "../../components/account-manage/list";
import { Form } from "../../components/form";
import { SelectField } from "../../components/form/select-field";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { IAccounts } from "../../interfaces/IAccounts";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";

const schema = yup.object({
  outAccId: yup.string().required("Заавал бөглөнө!").nullable(),
  inAccId: yup.string().required("Заавал бөглөнө!").nullable(),
  businessIds: yup.array().min(1, "Данс сонгоно уу!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  outAccId: string | undefined | null;
  inAccId: string | undefined | null;
  businessIds: any[];
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: string;
  label: string;
  name: string;
}

export function AccountManage() {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { bankAccounts } = useSelector((state: { general: IGeneral }) => state.general);
  const NET_CLS_SET = usePermission("NET_CLS_SET");

  const tableRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbs = useBreadcrumb();
  const [data] = useState<IFormData>({
    outAccId: null,
    inAccId: null,
    businessIds: [],
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await NetworkSetApi.account(values);
      message.success("Данс амжилттай холбогдлоо.");
      await tableRef?.current?.reload();
    } catch (error: any) {
      message.error(error.message || "");
    }
    setLoading(false);
  };

  let now = new Date();

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, name, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          {name}
        </Text>
      </div>
    </div>
  ));

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        return (
          <PageLayout
            breadcrumb={breadcrumbs}
            title={user?.currentBusiness?.type === "BUYER" ? "Нийлүүлэгчид данс тохируулах" : "Худалдан авагчид данс тохируулах"}
            subTitle={user?.currentBusiness?.type === "BUYER" ? "Гэрээт нийлүүлэгчид данс тохируулах" : "Гэрээт худалдан авагчид данс тохируулах"}>
            {NET_CLS_SET.hasAccess ? (
              <Paper p="md" withBorder>
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label="Тохиргоо хийсэн" required>
                      <Text c="indigo" fw={600}>
                        {dateTimeFormat(now)}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Input.Wrapper label="Ажилтан" required>
                      <Text c="indigo" fw={600}>
                        {user?.lastName || ""} {user?.firstName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
                <Divider my="lg" />
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <SelectField
                      itemComponent={SelectItem}
                      name="inAccId"
                      clearable
                      searchable
                      options={bankAccounts?.map((item: IAccounts | any) => {
                        return {
                          value: item.id,
                          label: `${item.shortName} / ${item.number}`,
                          name: item.name,
                          icon: item?.icon || "",
                        };
                      })}
                      label="Орлого хүлээн авах данс:"
                      placeholder="Орлого хүлээн авах данс"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <SelectField
                      itemComponent={SelectItem}
                      name="outAccId"
                      clearable
                      searchable
                      options={bankAccounts?.map((item: IAccounts | any) => {
                        return {
                          value: item.id,
                          label: `${item.shortName} / ${item.number}`,
                          name: item.name,
                          icon: item?.icon || "",
                        };
                      })}
                      label="Нэхэмжлэх төлөх данс:"
                      placeholder="Нэхэмжлэх төлөх данс"
                      required
                    />
                  </Grid.Col>
                </Grid>
                <Divider my="lg" />
                <Grid>
                  <Grid.Col span={12}>
                    <AccountManageList tableRef={tableRef} values={values} setFieldValue={setFieldValue} errors={errors} />
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <Group align="right">
                      <Button disabled={(values?.businessIds?.length || 0) === 0} type="submit" loading={loading}>
                        Тохиргоо батлах
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </Paper>
            ) : (
              NET_CLS_SET.accessDenied()
            )}
          </PageLayout>
        );
      }}
    </Form>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Данс тохиргоо",
  },
];
