import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Modal, Tooltip } from "@mantine/core";
import { IconInfoSquare, IconPhoto, IconPlus } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { PaymentTermApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPaymentTermItem } from "../../interfaces/IPaymentTermItem";
import { IReference } from "../../models/General";
import { renderName } from "../../utils/render-name";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { PopoverText } from "../popover-text/popover-text";
import { useScreenDetector } from "../screen-detector";
import { ColumnType, ITableRef, Table } from "../table";
import RenderText from "../ui/render-text";
import { CodTermForm } from "./cod-term-form";

export function CodTermList() {
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const params = useParams();
  const { paymentTermConditions } = useSelector((state: { general: IGeneral }) => state.general);
  const { businessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const { isMobile } = useScreenDetector();

  const NET_PT = usePermission("NET_PT");

  const [filters] = React.useState({
    query: "",
    configType: "",
    condition: `${params.type}`,
  });

  const columns = useHeader({
    paymentTermConditions,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
        case "edit": {
          setAction(["update", record as any]);
          break;
        }
      }
    },
  });

  const reload = () => {
    ref?.current?.reload();
  };

  return (
    <>
      <PageLayout
        title={`${paymentTermConditions.find((item: any) => item.code === params.type)?.name}`}
        subTitle={`${paymentTermConditions.find((item: any) => item.code === params.type)?.name} жагсаалт`}
        extra={[
          NET_PT.isCreate && (
            <div key={"2"}>
              {isMobile ? (
                <ActionIcon color="grape" variant="filled" onClick={() => setAction(["new"])} key={1}>
                  <IconPlus />
                </ActionIcon>
              ) : (
                businessType !== "BUYER" && (
                  <Button color="grape" variant="filled" onClick={() => setAction(["new"])} key={0}>
                    Нөхцөл нэмэх
                  </Button>
                )
              )}
            </div>
          ),
        ]}>
        <Divider mb="sm" />
        {NET_PT.isView ? (
          <Table ref={ref} name="cod.list" columns={columns} filters={filters} loadData={(data) => PaymentTermApi.list(data!)} pagination={false} />
        ) : (
          NET_PT.accessDenied()
        )}
      </PageLayout>
      <Modal
        opened={action[0] === "new" || action[0] === "update" || action[0] === "detail"}
        size="60rem"
        withCloseButton={false}
        onClose={() => setAction([])}
        centered>
        <CodTermForm
          action={action}
          reload={reload}
          onCancel={() => setAction([])}
          title="Нөхцөл"
          subTitle={
            (action[0] === "new" && `${paymentTermConditions.find((item: any) => item.code === params.type)?.name} нэмэх`) ||
            (action[0] === "update" && `${paymentTermConditions.find((item: any) => item.code === params.type)?.name} засварлах`) ||
            (action[0] === "detail" && `${paymentTermConditions.find((item: any) => item.code === params.type)?.name} дэлгэрэнгүй`)
          }
        />
      </Modal>
    </>
  );
}

const useHeader = ({
  onClick,
  paymentTermConditions,
}: {
  onClick: (key: string, record: IPaymentTermItem) => void;
  paymentTermConditions: IReference[];
}): ColumnType<IPaymentTermItem>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)} color="grape">
            <IconInfoSquare />
          </Button>
        </Tooltip>
      );
    },
  },
  {
    title: "Нөхцөл",
    dataIndex: "condition",
    width: "200px",
    render: (record) => <RenderText text={paymentTermConditions.find((f: any) => f.code === record.condition)?.name || "-"} />,
  },
  {
    title: "Код",
    width: "200px",
    dataIndex: "refCode",
    render: (record) => <RenderText text={record?.refCode || "-"} />,
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    width: "420px",
    render: (record) => <PopoverText>{record?.description || "-"}</PopoverText>,
  },
  {
    title: "Хоног",
    width: "100px",
    dataIndex: "expireDayCount",
    render: (record) => <RenderText text={record?.expireDayCount || "-"} />,
  },
  {
    title: "Сар",
    width: "100px",
    dataIndex: "month",
    render: (record) => <RenderText text={record?.month || "-"} />,
  },
  {
    title: "Өдөр",
    dataIndex: "paymentDay",
    render: (record) => <RenderText text={record?.paymentDay || "-"} />,
  },
  {
    title: "Статус",
    dataIndex: "isActive",
    render: (record) => (
      <Badge
        variant="light"
        color={record.isActive ? "primary" : "gray"}
        styles={{
          label: {
            overflow: "visible",
            textOverflow: "unset",
          },
        }}>
        {record?.isActive ? "Идэвхтэй" : "Идэвхгүй"}
      </Badge>
    ),
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "modifiedUser",
    width: "320px",
    render: (record) =>
      record?.modifiedUserId === "DEP_ADMIN" ? (
        <Badge>админ</Badge>
      ) : (
        <Flex align="center" gap="sm">
          <Avatar src={record?.modifiedUser?.avatar}>
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={renderName(record?.modifiedUser)} />
            <RenderText text={record?.modifiedUser?.email} />
          </Box>
        </Flex>
      ),
  },
  {
    title: "Default эсэх",
    dataIndex: "isMain",
    render: (record) => (
      <Badge
        variant="light"
        color={record.isMain ? "primary" : "gray"}
        styles={{
          label: {
            overflow: "visible",
            textOverflow: "unset",
          },
        }}>
        {record?.isMain ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "DeHUB стандарт",
    dataIndex: "isOpen",
    render: (record) => (
      <Badge
        variant="light"
        color={record.isOpen ? "primary" : "gray"}
        styles={{
          label: {
            overflow: "visible",
            textOverflow: "unset",
          },
        }}>
        {record?.isOpen ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
];
