import dayjs from "dayjs";

// date and date-time formats in dayjs
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_SEC = "YYYY-MM-DD HH:mm";

export function dateFormat(date?: string): string {
  return date ? dayjs(date).format(DATE_FORMAT) : "-";
}

export function dateTimeFormat(date?: string | Date): string {
  return date ? dayjs(date).format(DATE_TIME_FORMAT) : "-";
}

export function dateSecFormat(date?: string | Date): string {
  return date ? dayjs(date).format(DATE_TIME_FORMAT_SEC) : "-";
}
