import { Avatar, Box, Checkbox, Divider, Flex, Tabs } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NetworkBusinessApi } from "../../apis";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IBusiness } from "../../interfaces/IBusiness";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import RenderText from "../ui/render-text";

export function AccountManageList({
  values,
  setFieldValue,
  errors,
  tableRef,
}: {
  values: any;
  setFieldValue: any;
  errors: any;
  tableRef: React.Ref<ITableRef>;
}) {
  const { paymentTermConfigTypes, businessStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters, setFilters] = useState<any>({ query: "", setAccount: "true" });

  const columns = useHeader({
    businessStatus,
    businessTypes,
    paymentTermConfigTypes,
    onClick: (key, item) => {},
    setFieldValue,
    values,
  });

  return (
    <div>
      <Tabs
        onChange={(e: string | null) => {
          setFilters({ query: "", setAccount: e });
          setFieldValue("businessIds", []);
        }}
        variant="pills"
        value={`${filters.setAccount}`}>
        <Tabs.List>
          <Tabs.Tab value="true">Дансны тохиргоо хийх бизнес</Tabs.Tab>
          <Tabs.Tab value="false">Данс тохируулсан бизнес</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Divider label={`Сонгосон бизнесийн тоо ${values?.businessIds?.length ?? 0}`} mt={"md"} />
      <Table
        placeholder={"Танд дансны тохиргоо хийх шаардлагатай хамтрагч бизнес байхгүй байна"}
        ref={tableRef}
        name="network.invitation.received.supplier.invite"
        columns={columns}
        filters={filters}
        loadData={(data) => NetworkBusinessApi.list(data!)}
      />
    </div>
  );
}

const useHeader = ({
  paymentTermConfigTypes,
  businessStatus,
  businessTypes,
  onClick,
  values,
  setFieldValue,
}: {
  paymentTermConfigTypes: IReference[];
  businessStatus: IReference[];
  businessTypes: IReference[];
  values: any;
  onClick: (e: string, e2: any) => void;
  setFieldValue: any;
}): ColumnType<IBusiness>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "",
    renderTitle: (data: any) => {
      const allSelected = data?.rows?.length > 0 && data?.rows?.every((item: any) => values?.businessIds?.includes(item.id));

      return (
        <Checkbox
          onChange={(e) => {
            if (!e.target.checked) {
              setFieldValue("businessIds", []);
            } else {
              setFieldValue(
                "businessIds",
                data?.rows?.map((item: any) => item.id),
              );
            }
          }}
          checked={allSelected}
          indeterminate={values?.businessIds?.length > 0 && !allSelected}
          onClick={() => {}}
        />
      );
    },
    render: (record) => {
      return (
        <Checkbox
          onClick={() => {
            if (values?.businessIds?.some((item: string) => item === record.id)) {
              setFieldValue("businessIds", [...(values?.businessIds?.filter((f: string) => f !== record.id) ?? [])]);
            } else {
              setFieldValue("businessIds", [...(values?.businessIds ?? []), record.id]);
            }
          }}
          onChange={() => {}}
          checked={values?.businessIds?.some((item: string) => item === record.id) ?? false}
        />
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.profileName ?? "-"} />
        <RenderText text={`#${record?.refCode ?? "-"}`} color="indigo" />
      </Box>
    ),
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.partner?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={record?.partner?.businessName ?? "-"} />
            <RenderText text={`#${record?.partner?.refCode ?? "-"}`} />
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "ТТД",
    render: (record) => <RenderText text={record?.partner?.regNumber ?? record?.regNumber ?? "-"} />,
  },
  {
    title: "Орлого хүлээн авах данс",
    dataIndex: "inAcc",
    render: (record) =>
      record.inAcc ? (
        <Flex align="center" gap="sm">
          <Avatar src={record.inAcc.icon} radius="xs">
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={`${record?.inAcc?.bankName ?? "-"} ${record?.inAcc?.number ?? "-"}`} />
            <RenderText text={record.inAcc?.shortName ?? ""} color="dimmed" />
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: " Нэхэмжлэх төлөх данс",
    dataIndex: "outAcc",
    render: (record) =>
      record.inAcc ? (
        <Flex align="center" gap="sm">
          <Avatar src={record.outAcc.icon}>
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={`${record?.outAcc?.bankName ?? "-"} ${record?.outAcc?.number ?? "-"}`} />
            <RenderText text={record.outAcc?.shortName ?? ""} color="dimmed" />
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Ангилал",
    dataIndex: "classificationCategory",
    render: (record) =>
      record?.classificationCategory ? (
        <Box>
          <RenderText text={record?.classificationCategory?.name ?? "-"} />
          <RenderText text={`#${record?.classificationCategory?.refCode ?? "-"}`} />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    dataIndex: "classificationPriority",
    render: (record) => <RenderText text={record?.classificationPriority?.name ?? "-"} />,
  },
];

const businessTypes = [
  {
    code: "SUPPLIER",
    name: "Нийлүүлэгч",
  },
  {
    code: "BUYER",
    name: "Худалдан авагч",
  },
];
