import { useEffect, useState } from "react";

type Breakpoints = {
  mobile: number;
  tablet: number;
};

type ScreenDetectorReturn = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export const useScreenDetector = (breakpoints: Breakpoints = { mobile: 768, tablet: 1024 }): ScreenDetectorReturn => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const debounce = (fn: () => void, delay: number) => {
      let timeout: NodeJS.Timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(fn, delay);
      };
    };

    const debouncedHandleWindowSizeChange = debounce(handleWindowSizeChange, 100);
    window.addEventListener("resize", debouncedHandleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", debouncedHandleWindowSizeChange);
    };
  }, []);

  const { mobile, tablet } = breakpoints;
  const isMobile = width <= mobile;
  const isTablet = width > mobile && width <= tablet;
  const isDesktop = width > tablet;

  return { isMobile, isTablet, isDesktop };
};
