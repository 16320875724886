import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Modal, Tooltip } from "@mantine/core";
import { IconEdit, IconInfoSquare, IconPhoto, IconPlus } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { DistributionApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { ISales } from "../../interfaces/ISales";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { PopoverText } from "../popover-text/popover-text";
import { useScreenDetector } from "../screen-detector";
import { ColumnType, ITableRef, Table } from "../table";
import RenderText from "../ui/render-text";
import { RegionSalesForm } from "./sales-region-form";

export function SalesRegionList() {
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const { isMobile } = useScreenDetector();
  const NET_REF_AREA = usePermission("NET_REF_AREA");
  const { businessType } = useSelector((state: { auth: IAuth }) => state.auth);

  const [filters] = React.useState({
    isParent: `${true}`,
  });

  const columns = useHeader({
    isView: NET_REF_AREA.isView,
    isEdit: NET_REF_AREA.isEdit,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
        case "edit": {
          setAction(["update", record as any]);
          break;
        }
      }
    },
  });

  const reload = () => {
    ref?.current?.reload();
  };

  return (
    <>
      <PageLayout
        title="Борлуулалтын бүс"
        subTitle="Борлуулалтын бүсийн жагсаалт"
        extra={[
          NET_REF_AREA.isCreate &&
            (isMobile ? (
              <ActionIcon color="grape" variant="filled" onClick={() => setAction(["new"])} key={0}>
                <IconPlus />
              </ActionIcon>
            ) : (
              businessType !== "BUYER" && (
                <Button color="grape" variant="filled" onClick={() => setAction(["new"])} key={0}>
                  Бүс нэмэх
                </Button>
              )
            )),
        ]}>
        <Divider mb="sm" />
        {NET_REF_AREA.isView ? (
          <Table ref={ref} name="region.sales.list" columns={columns} filters={filters} loadData={(data) => DistributionApi.list(data!)} pagination={false} />
        ) : (
          NET_REF_AREA.accessDenied()
        )}
      </PageLayout>
      <Modal
        opened={action[0] === "new" || action[0] === "update" || action[0] === "detail"}
        size="60rem"
        withCloseButton={false}
        onClose={() => setAction([])}
        centered>
        <RegionSalesForm
          action={action}
          reload={reload}
          onCancel={() => setAction([])}
          title="Борлуулалтын бүс"
          subTitle={
            (action[0] === "new" && "Борлуулалтын бүсчлэл нэмэх.") ??
            (action[0] === "update" && "Борлуулалтын бүсчлэлийг засварлах.") ??
            (action[0] === "detail" && "Борлуулалтын бүсчлэлийн дэлгэрэнгүй.")
          }
        />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ISales) => void;
  isEdit: boolean;
  isView: boolean;
};

const useHeader = ({ onClick, isEdit, isView }: HeaderProps): ColumnType<ISales>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {isView && (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)} color="grape">
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
          {isEdit && (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} color="grape">
                <IconEdit />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.business?.profileName ?? "-"} />
          <RenderText text={record?.business?.refCode ?? "-"} color="indigo" />
        </Box>
      );
    },
  },
  {
    title: "Бүсийн код",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Badge
          color="grape"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "ellipsis",
            },
          }}>
          {record?.refCode}
        </Badge>
      );
    },
  },
  {
    title: "Чиглэл нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => <RenderText text={record?.name ?? "-"} />,
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText>{record?.description ?? "-"}</PopoverText>,
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return record?.regUser ? (
        <Flex align="center" gap="sm">
          <Avatar src={`${record?.regUser?.avatar}`}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.regUser)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Бүртгэсэн огноо, цаг",
    sorter: true,
    dataIndex: "ref",
    render: (record) => <RenderText text={dateSecFormat(record.createdAt)} />,
  },
];
