import { Text } from "@mantine/core";
import { CSSProperties } from "react";

interface RenderTextProps {
  text: string | number;
  isAmount?: boolean;
  color?: string;
  fontWeight?: number;
  style?: CSSProperties;
}

const RenderText = ({ text, isAmount, color, fontWeight, style }: RenderTextProps) => {
  return isAmount ? (
    <Text size="sm" fw={fontWeight ? fontWeight : 500} c={color ? color : "#444"} ta="end" style={{ whiteSpace: "nowrap", ...(style ? style : undefined) }}>
      {text}
    </Text>
  ) : (
    <Text size="sm" fw={fontWeight ? fontWeight : 500} c={color ? color : "#444"} w="max-content" style={style ? style : undefined}>
      {text}
    </Text>
  );
};

export default RenderText;
