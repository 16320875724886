import { Alert, Avatar, Badge, Box, Button, CloseButton, Divider, Flex, Grid, Group, Paper, Space, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { ClientStaffApi, NetworkSetApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { message } from "../../utils/Message";
import { renderName } from "../../utils/render-name";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { usePermission } from "../permission";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

const schema = yup.object({
  businessIds: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  businessStaffId: yup.string().required("Заавал бөглөнө!").nullable(),
  businessStaff2Id: yup.string().nullable(),
  businessStaffDesc: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  businessIds: string[];
  businessStaffId: string | undefined;
  businessStaff2Id: string | undefined;
  businessStaffDesc: string | undefined;
};

type IFormProps = {
  action: any[];
  setAction: (e: any) => void;
  title: string;
  subTitle: string;
  reload?: any;
};

export function StaffSetForm({ action, setAction, title, subTitle, reload }: IFormProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { businessStatus, staffs: rawStaffs } = useSelector((state: { general: IGeneral }) => state.general);
  const NET_STF_SET = usePermission("NET_STF_SET");

  const staffs = rawStaffs?.filter((item) => item && Object.keys(item).length > 0 && item.id && (item.firstName || item.lastName)) ?? [];

  const [data] = useState<IFormData>({
    businessStaffId: undefined,
    businessStaff2Id: undefined,
    businessStaffDesc: undefined,
    businessIds:
      action[1]?.map((item: any) => {
        return item.id;
      }) ?? [],
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    let now = new Date();
    try {
      await NetworkSetApi.client_staff({
        businessIds: values.businessIds ?? [],
        businessStaffId: values.businessStaffId,
        businessStaff2Id: values?.businessStaff2Id,
        businessStaffDesc: values.businessStaffDesc,
      });
      message.success(`Амжилттай хадгалагдлаа. ${dayjs(now).format("YYYY-MM-DD HH:mm")}`);
      reload();
      setAction([]);
    } catch (error: any) {
      message.error(error.message ?? "Хүсэлт амжилтгүй.");
    }
    setLoading(false);
  };

  const columns = useHeader({ businessStatus });

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Group justify="space-between" align="center">
              <div>
                <Text fz="lg" fw={600}>
                  {title}
                </Text>
                <Text c="dimmed" size="sm">
                  {title}
                </Text>
              </div>
              <CloseButton key="cancel" onClick={() => setAction && setAction([])} />
            </Group>
            <Space h="lg" />
            <Paper>
              <Divider mb="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <Text c="dimmed">Харилцагчийн мэдээлэл</Text>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    onChange={() => {
                      setFieldValue("businessStaff2Id", undefined);
                    }}
                    name="businessStaffId"
                    required
                    label="Хариуцсан ажилтан сонгох"
                    placeholder="Сонгоно уу"
                    clearable
                    options={
                      staffs
                        ?.filter((item) => item && Object.keys(item).length > 0 && item.id && (item.firstName || item.lastName))
                        .map((item) => ({
                          label: `${item.lastName ?? ""} ${item.firstName ?? ""}`.trim(),
                          value: String(item.id),
                        })) ?? []
                    }
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <SelectField
                    disabled={!values.businessStaffId}
                    name="businessStaff2Id"
                    label="Орлох ажилтан сонгох"
                    placeholder="Сонгоно уу"
                    clearable
                    options={
                      staffs
                        ?.filter((item: { id: string }) => values?.businessStaffId !== item.id)
                        ?.map((item: { firstName: string; id: string; lastName: string }) => {
                          return {
                            label: `${item.lastName} ${item.firstName}`,
                            value: item.id,
                          };
                        }) ?? []
                    }
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="businessStaffDesc" required label="Тайлбар" placeholder="Тайлбар бичнэ үү" />
                </Grid.Col>
                <Grid.Col>
                  <Text c="dimmed">Сонгосон бизнесүүд</Text>
                  <Space h={"xs"} />
                  <Table pagination={false} name="businessStaffDesc.list" columns={columns} filters={{}} dataSource={(action[1] && action[1]) ?? []} />
                </Grid.Col>
                <Grid.Col>
                  <Text c="dimmed">Сонгосон ажилтны хариуцаж буй бизнесүүд</Text>
                  <Space h={"xs"} />
                  {values?.businessStaffId ? (
                    <Box w="100%" style={{ overflow: "auto" }}>
                      <Table
                        name="selected.staff.bussiness.list"
                        columns={columns}
                        filters={{ staffId: values?.businessStaffId }}
                        loadData={(data) => ClientStaffApi.business_list(data!)}
                      />
                    </Box>
                  ) : (
                    <Alert title="Санамж">Хариуцсан ажилтан сонгоно уу.</Alert>
                  )}
                </Grid.Col>

                <Grid.Col span={12} hidden={!NET_STF_SET.hasAccess}>
                  <Group justify="right">
                    <Button variant="default" onClick={() => setAction([])}>
                      Болих
                    </Button>
                    <Button loading={loading} type="submit">
                      Хадгалах
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Paper>
          </>
        );
      }}
    </Form>
  );
}

const useHeader = ({ businessStatus }: { businessStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <RenderText text={record?.regNumber ?? "-"} />,
  },
  {
    title: "Партнерийн нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.partner?.businessName ?? "-"} />
        <RenderText text={`#${record?.partner?.refCode ?? "-"}`} />
      </Box>
    ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.profileName ?? "-"} />
        <RenderText text={`#${record?.refCode ?? "-"}`} />
      </Box>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      const branch = record?.branch;

      if (branch?.name) {
        return (
          <Box>
            <RenderText text={branch.name} />
            <RenderText text={`#${branch.refCode || "-"}`} color="indigo" />
          </Box>
        );
      }

      return "-";
    },
  },
  {
    title: "Статус",
    dataIndex: "name",
    width: "200px",
    render: (record) => (
      <Badge
        color={businessStatus.find((item: IReference) => item.code === record?.businessStatus)?.color ?? "indigo"}
        variant="light"
        styles={{
          label: {
            overflow: "visible",
            textOverflow: "unset",
          },
        }}>
        {businessStatus.find((item: IReference) => item.code === record?.businessStatus)?.name ?? "-"}
      </Badge>
    ),
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.coBusinessAreaDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Хариуцсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaff?.firstName ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.businessStaff?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.businessStaff)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Орлох ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaff2?.firstName ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.businessStaff2?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.businessStaff2)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Тохиргоо хийсэн",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaffSetUser?.firstName ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.businessStaffSetUser?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.businessStaffSetUser)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
];
