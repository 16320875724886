import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Modal, Tooltip } from "@mantine/core";
import { IconEdit, IconInfoSquare, IconPhoto, IconPlus } from "@tabler/icons-react";
import React from "react";
import { ClientClassificationsApi } from "../../apis";
import { IClientClassification } from "../../interfaces/IClientClassification";
import { dateTimeFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { PopoverText } from "../popover-text/popover-text";
import { useScreenDetector } from "../screen-detector";
import { ColumnType, ITableRef, Table } from "../table";
import RenderText from "../ui/render-text";
import { ClientCateoryForm } from "./client-category-form";

export function ClientCateoryList() {
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const { isMobile } = useScreenDetector();
  const NET_REF_CLS = usePermission("NET_REF_CLS");

  const [filters] = React.useState({
    isParent: `${true}`,
  });

  const columns = useHeader({
    isView: NET_REF_CLS.isView,
    isEdit: NET_REF_CLS.isEdit,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
        case "edit": {
          setAction(["update", record as any]);
          break;
        }
      }
    },
  });

  const reload = () => {
    ref?.current?.reload();
  };

  return (
    <>
      <PageLayout
        title="Нийлүүлэгчийн ангилал"
        subTitle="Нийлүүлэгчийн ангиллын жагсаалт"
        extra={
          NET_REF_CLS.isCreate
            ? [
                !isMobile ? (
                  <Button color="grape" variant="filled" onClick={() => setAction(["new"])} key={0}>
                    Ангилал нэмэх
                  </Button>
                ) : (
                  <ActionIcon color="grape" variant="filled" onClick={() => setAction(["new"])} key={1} size={36}>
                    <IconPlus />
                  </ActionIcon>
                ),
              ]
            : []
        }>
        <Divider mb="sm" />
        {NET_REF_CLS.isView ? (
          <Table
            ref={ref}
            name="client.category.list"
            columns={columns}
            filters={filters}
            loadData={(data) => ClientClassificationsApi.list(data!)}
            pagination={false}
          />
        ) : (
          NET_REF_CLS.accessDenied()
        )}
      </PageLayout>
      <Modal
        opened={action[0] === "new" || action[0] === "update" || action[0] === "detail"}
        size="60rem"
        withCloseButton={false}
        onClose={() => setAction([])}
        centered>
        <ClientCateoryForm
          action={action}
          reload={reload}
          onCancel={() => setAction([])}
          title="Нийлүүлэгчийн ангилал"
          subTitle={
            (action[0] === "new" && "Нийлүүлэгчийн ангилал нэмэх.") ||
            (action[0] === "update" && "Нийлүүлэгчийн ангиллыг засварлах.") ||
            (action[0] === "detail" && "Нийлүүлэгчийн ангиллын дэлгэрэнгүй.")
          }
        />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IClientClassification) => void;
  isView: boolean;
  isEdit: boolean;
};

const useHeader = ({ onClick, isEdit, isView }: HeaderProps): ColumnType<IClientClassification>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {isView && (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)} color="grape">
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
          {isEdit && (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} color="grape">
                <IconEdit />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.business?.profileName ?? "-"} />
          <RenderText text={record?.business?.refCode ?? "-"} color="indigo" />
        </Box>
      );
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Badge
          color="grape"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          {record?.refCode || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Badge
          color="grape"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          {record?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "ref",
    render: (record) => <PopoverText>{record?.description || "-"}</PopoverText>,
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return record?.regUser ? (
        <Flex align="center" gap="sm">
          <Avatar src={`${record?.regUser?.avatar}`}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.regUser)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Бүртгэсэн огноо, цаг",
    sorter: true,
    dataIndex: "ref",
    render: (record) => <RenderText text={dateTimeFormat(record.createdAt)} />,
  },
];
