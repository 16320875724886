import { Anchor, Avatar, Box, Flex, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import RenderText from "../../components/ui/render-text";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";

export const LastInvitationInvitedList = ({ receivedInvitations }: { receivedInvitations: any[] }) => {
  const refTable = useRef<ITableRef>(null);
  const { invitationStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ invitationStatus });

  return (
    <Table
      pagination={false}
      ref={refTable}
      name="last.invitation.list"
      columns={columns}
      filters={{
        query: "",
        type: "NETWORK",
      }}
      dataSource={receivedInvitations || []}
    />
  );
};

const useHeader = ({ invitationStatus }: { invitationStatus: IReference[] }): ColumnType<any>[] => [
  {
    title: "Урилга №",
    render: (record) => (
      <Anchor>
        <RenderText text={`#${record?.refCode ?? "-"}`} />
      </Anchor>
    ),
  },
  {
    title: "Статус",
    render: (record) =>
      Tag(
        invitationStatus?.find((item: any) => item.code === record.invitationStatus)?.color ?? "blue",
        invitationStatus?.find((item: any) => item.code === record.invitationStatus)?.name ?? "-",
      ),
  },
  {
    title: "Илгээсэн партнер",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={record?.sender?.partner?.logo}>
          <IconPhoto />
        </Avatar>
        <Box>
          <Text size="sm" w="max-content">
            {record?.sender?.partner?.businessName ?? "-"}
          </Text>
          <Text size="sm" w="max-content" c="indigo">
            #{record?.sender?.partner?.refCode ?? "-"}
          </Text>
        </Box>
      </Flex>
    ),
  },
  {
    title: "Илгээсэн бизнес",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={record?.sender?.logo}>
          <IconPhoto />
        </Avatar>
        <Box>
          <Text size="sm" w="max-content">
            {record?.sender?.profileName ?? "-"}
          </Text>
          <Text size="sm" w="max-content" c="indigo">
            #{record?.sender?.refCode ?? "-"}
          </Text>
        </Box>
      </Flex>
    ),
  },
  {
    title: "Ирсэн огноо, цаг",
    render: (record) => (
      <Text size="sm" w="max-content">
        {dateTimeFormat(record.invitedDate)}
      </Text>
    ),
  },
];
