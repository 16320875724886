import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Modal, Tooltip } from "@mantine/core";
import { IconEdit, IconInfoSquare, IconPhoto, IconPlus } from "@tabler/icons-react";
import React from "react";
import { ClientClassificationsApi } from "../../apis";
import { IClientClassification } from "../../interfaces/IClientClassification";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { PopoverText } from "../popover-text/popover-text";
import { useScreenDetector } from "../screen-detector";
import { ColumnType, ITableRef, Table } from "../table";
import RenderText from "../ui/render-text";
import { ClientPriorityForm } from "./client-priority-form";

export function ClientPriorityList() {
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const { isMobile } = useScreenDetector();

  const NET_REF_CLS = usePermission("NET_REF_CLS");

  const [filters] = React.useState({
    isParent: `${false}`,
  });

  const columns = useHeader({
    isView: NET_REF_CLS.isView,
    isEdit: NET_REF_CLS.isEdit,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
        case "edit": {
          setAction(["update", record as any]);
          break;
        }
      }
    },
  });

  const reload = () => {
    ref?.current?.reload();
  };

  return (
    <>
      <PageLayout
        title="Нийлүүлэгчийн зэрэглэл"
        subTitle="Нийлүүлэгчийн зэрэглэлийн жагсаалт"
        extra={[
          NET_REF_CLS.isCreate &&
            (!isMobile ? (
              <Button color="grape" variant="filled" onClick={() => setAction(["new"])} key={0}>
                Зэрэглэл нэмэх
              </Button>
            ) : (
              <ActionIcon size={36} onClick={() => setAction(["new"])} key={1} color="grape" variant="filled">
                <IconPlus />
              </ActionIcon>
            )),
        ]}>
        <Divider mb="sm" />
        {NET_REF_CLS.isView ? (
          <Table
            ref={ref}
            name="client.priority.list"
            columns={columns}
            filters={filters}
            loadData={(data) => ClientClassificationsApi.list(data!)}
            pagination={false}
          />
        ) : (
          NET_REF_CLS.accessDenied()
        )}
      </PageLayout>
      <Modal
        opened={action[0] === "new" || action[0] === "update" || action[0] === "detail"}
        size="60rem"
        withCloseButton={false}
        onClose={() => setAction([])}
        centered>
        <ClientPriorityForm
          action={action}
          reload={reload}
          onCancel={() => setAction([])}
          title="Нийлүүлэгчийн зэрэглэл"
          subTitle={
            (action[0] === "new" && "Нийлүүлэгчийн зэрэглэл нэмэх.") ??
            (action[0] === "update" && "Нийлүүлэгчийн зэрэглэлийг засварлах.") ??
            (action[0] === "detail" && "Нийлүүлэгчийн зэрэглэлийн дэлгэрэнгүй.")
          }
        />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IClientClassification) => void;
  isEdit: boolean;
  isView: boolean;
};

const useHeader = ({ onClick, isEdit, isView }: HeaderProps): ColumnType<IClientClassification>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {isView && (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)} color="grape">
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
          {isEdit && (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} color="grape">
                <IconEdit />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.business?.profileName ?? "-"} />
          <RenderText text={record?.business?.refCode ?? "-"} color="indigo" />
        </Box>
      );
    },
  },
  {
    title: "Ангилал код",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Badge
          color="grape"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          {record?.parent?.refCode ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Ангиллын нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return (
        <Badge
          color="grape"
          radius="sm"
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
              textOverflow: "unset",
            },
          }}>
          {record?.parent?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Зэрэглэл нэр",
    sorter: true,
    dataIndex: "ref",
    render: (record) => <RenderText text={record?.name ?? "-"} />,
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "ref",
    render: (record) => <PopoverText>{record?.description ?? "-"}</PopoverText>,
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return record?.regUser ? (
        <Flex align="center" gap="sm">
          <Avatar src={`${record?.regUser?.avatar}`}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.regUser)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Бүртгэсэн огноо, цаг",
    sorter: true,
    dataIndex: "ref",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.createdAt)} />;
    },
  },
];
