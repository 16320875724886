import { Avatar, Box, Checkbox, Flex, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import React from "react";
import { NetworkBusinessApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function RankList({
  action,
  setAction,
  selected,
  setSelected,
  refTable,
}: {
  action: any;
  setAction: any;
  selected: string[];
  setSelected: (e: string[]) => void;
  reload: () => void;
  refTable: any;
}) {
  const columns = useHeader({
    selected,
    onClick: (key: string, item: any) => {
      if (key === "unselect_all") {
        setSelected([]);
      } else if (key === "select_all") {
        setSelected(item);
      } else if (key === "select") {
        if (selected.some((item1: any) => item1.id === item.id)) {
          setSelected([...selected.filter((item1: any) => item1.id !== item.id)]);
        } else setSelected([...selected, item]);
      } else setAction([key, { ...item, businessIds: selected }]);
    },
  });

  const [filters] = React.useState({ query: "" });

  return <Table ref={refTable} name="rank.category.list" columns={columns} filters={filters} loadData={(data) => NetworkBusinessApi.list(data!)} />;
}

const useHeader = ({ onClick, selected }: { onClick: (e: string, e1: any) => void; selected: any[] }): ColumnType<any>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "",
    width: 30,
    renderTitle: (data) => {
      return (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              onClick("select_all", data?.rows ?? []);
            } else onClick("unselect_all", []);
          }}
          onClick={() => {}}
          checked={(selected?.length ?? 0) === data?.rows?.length}
        />
      );
    },
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={selected?.some((item: any) => item?.id === record?.id)}
        />
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <RenderText text={record?.regNumber ?? "-"} />,
  },
  {
    title: "Партнерийн нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.partner?.businessName ?? "-"} />
        <RenderText text={`#${record?.partner?.refCode ?? "-"}`} color="indigo" />
      </Box>
    ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.profileName ?? "-"} />
        <RenderText text={`#${record?.refCode ?? "-"}`} color="indigo" />
      </Box>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      const branch = record?.branch;

      if (branch?.name) {
        return (
          <Box>
            <RenderText text={branch.name} />
            <RenderText text={`#${branch.refCode || "-"}`} color="indigo" />
          </Box>
        );
      }

      return "-";
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    width: "200px",
    render: (record) =>
      record?.classificationCategory ? (
        <Box>
          <RenderText text={record?.classificationCategory?.name ?? "-"} />
          <RenderText text={`#${record?.classificationCategory?.refCode ?? "-"}`} color="indigo" />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.classificationPriority?.name)
        return (
          <Box>
            <RenderText text={record?.classificationPriority?.name ?? "-"} />
            <PopoverText>{record?.classificationPriority?.description ?? "-"}</PopoverText>
          </Box>
        );
      else return "-";
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.classificationDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Тохиргоо хийсэн",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.classificationSetUser?.firstName) {
        return (
          <Flex align="center" gap="sm">
            <Avatar src={record?.classificationSetUser?.avatar}>
              <IconPhoto />
            </Avatar>
            <RenderText text={renderName(record?.classificationSetUser)} />
          </Flex>
        );
      } else return "-";
    },
  },
  {
    title: "Өөрчлөлт хийсэн огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <RenderText text={record?.classificationSetDate ? dateTimeFormat(record?.classificationSetDate) : "-"} />,
  },
];
