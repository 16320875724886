import { Avatar, Box, Checkbox, Flex, LoadingOverlay } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { NetworkInvitationApi } from "../../apis";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

type IType = {
  action: any[];
  values: any;
  setFieldValue: (e1: string, e2: any) => void;
};

export function InviteSupplierList({ action, setFieldValue, values }: IType) {
  const columns = useHeader({
    setFieldValue,
    values,
  });

  if (action[0] !== "invite") return <LoadingOverlay visible />;
  else
    return (
      <Table
        name={`supplier.receiverIds.${JSON.stringify((action[0] === "invite" && action[1]).partnerId)}`}
        columns={columns}
        filters={{ query: "", partnerId: (action[0] === "invite" && action[1]).partnerId }}
        loadData={(data) => NetworkInvitationApi.business_suggest(data!)}
      />
    );
}

const useHeader = ({ setFieldValue, values }: { values: any; setFieldValue: (e1: string, e2: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "",
    renderTitle: (data) => {
      return (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue(`receiverIds`, [...(data.rows?.map((ite: any) => ite.id) ?? [])]);
            } else {
              setFieldValue(`receiverIds`, []);
            }
          }}
        />
      );
    },
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {
            if (values?.receiverIds?.some((item: string) => item === record.id) ?? false) {
              setFieldValue(`receiverIds`, [...values?.receiverIds.filter((fil: string) => fil !== record.id)]);
            } else setFieldValue(`receiverIds`, [...(values?.receiverIds ?? []), record.id]);
          }}
          checked={values?.receiverIds?.some((item: string) => item === record.id) ?? false}
        />
      );
    },
  },
  {
    title: "Партнер нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.partnerName ?? "-"} />
        <RenderText text={record?.partner?.refCode ?? "-"} />
      </Box>
    ),
  },
  {
    title: "Supplier Бизнес",
    render: (record) => (
      <Box>
        <RenderText text={record?.profileName ?? "-"} />
        <RenderText text={record?.refCode ?? "-"} />
      </Box>
    ),
  },
  {
    title: "ТТД",
    render: (record) => <RenderText text={record?.regNumber ?? "-"} />,
  },
  {
    title: "Supplier админ",
    render: (record) =>
      record?.staff ? (
        <Flex align="center" gap="sm">
          <Avatar size={40} src={record?.staff?.avatar} radius={100}>
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={renderName(record?.staff)} />
            <RenderText text={record?.staff?.email} />
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Утасны дугаар",
    render: (record) => <RenderText text={record?.staff?.phone} />,
  },
];
