import { Avatar, Badge, Box, Checkbox, Flex, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { NetworkBusinessApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function DistributionAreaList({
  action,
  setAction,
  selected,
  setSelected,
  refTable,
}: {
  action: any;
  setAction: any;
  selected: string[];
  setSelected: (e: string[]) => void;
  reload: () => void;
  refTable: any;
}) {
  const { businessStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { businessType } = useSelector((state: { auth: IAuth }) => state.auth);

  const columns = useHeader({
    selected,
    businessStatus,
    onClick: (key: string, item: any) => {
      if (key === "select_all") {
        setSelected(item);
      }
      if (key === "unselect_all") {
        setSelected([]);
      }
      if (key === "select") {
        if (selected.some((item1: any) => item1.id === item.id)) {
          setSelected([...selected.filter((item1: any) => item1.id !== item.id)]);
        } else setSelected([...selected, item]);
      } else setAction([key, { ...item, businessIds: selected }]);
    },
  });

  const columnsBuyerView = useHeaderBuyerView({
    selected,
    businessStatus,
    onClick: (key: string, item: any) => {
      if (key === "select_all") {
        setSelected(item);
      }
      if (key === "unselect_all") {
        setSelected([]);
      }
      if (key === "select") {
        if (selected.some((item1: any) => item1.id === item.id)) {
          setSelected([...selected.filter((item1: any) => item1.id !== item.id)]);
        } else setSelected([...selected, item]);
      } else setAction([key, { ...item, businessIds: selected }]);
    },
  });

  const [filters] = React.useState({ query: "" });

  return (
    <Table
      ref={refTable}
      name="NetworkBusinessApi.list"
      columns={businessType === "BUYER" ? columnsBuyerView : columns}
      filters={filters}
      loadData={(data) => NetworkBusinessApi.list(data!)}
    />
  );
}

const useHeader = ({
  onClick,
  selected,
  businessStatus,
}: {
  onClick: (e: string, e1: any) => void;
  selected: any[];
  businessStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    renderTitle(record) {
      return (
        <Checkbox
          onChange={(e) => {
            if (!e.target.checked) {
              onClick("unselect_all", []);
            } else onClick("select_all", record?.rows);
          }}
          onClick={() => {}}
          checked={selected?.length === record?.rows?.length}
        />
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={selected.some((item: any) => item.id === record.id)}
        />
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <RenderText text={record?.regNumber ?? "-"} />,
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <Box>
          <RenderText text={record?.partner?.businessName ?? "-"} />
          <RenderText text={`#${record?.partner?.refCode ?? "-"}`} color="indigo" />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.profileName ?? "-"} />
        <RenderText text={`#${record?.refCode ?? "-"}`} />
      </Box>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      const branch = record?.branch;

      if (branch?.name) {
        return (
          <Box>
            <RenderText text={branch.name} />
            <RenderText text={`#${branch.refCode ?? "-"}`} color="indigo" />
          </Box>
        );
      }

      return "-";
    },
  },
  {
    title: "Ангилал",
    render: (record) =>
      record?.classificationCategory ? (
        <Box>
          <RenderText text={record?.classificationCategory?.name ?? "-"} />
          <RenderText text={`#${record?.classificationCategory?.refCode ?? "-"}`} color="indigo" />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Статус",
    dataIndex: "businessStatus",
    width: "200px",
    render: (record) => (
      <Badge
        color={businessStatus.find((item: IReference) => item.code === record.businessStatus)?.color ?? "indigo"}
        styles={{ label: { overflow: "visible", textOverflow: "unset" } }}
        variant="light">
        {businessStatus.find((item: IReference) => item.code === record.businessStatus)?.name ?? "-"}
      </Badge>
    ),
  },
  {
    title: "Бүсийн нэр",
    dataIndex: "areaRegion",
    width: "200px",
    render: (record) =>
      record?.areaRegion ? (
        <Box>
          <RenderText text={record?.areaRegion?.name ?? "-"} />
          <RenderText text={`#${record?.areaRegion?.refCode ?? "-"}`} />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Чиглэлийн нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => (
      <Box>
        <RenderText text={record?.areaDirection?.name ?? "-"} />
        <Text size="sm">{record?.areaDirection?.name ?? "-"}</Text>
        {record?.areaDirection?.refCode && <RenderText text={`#${record?.areaDirection?.refCode ?? "-"}`} color="indigo" />}
      </Box>
    ),
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.areaDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Хариуцсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaff?.firstName ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.businessStaff?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.businessStaff)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Орлох ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.businessStaff2 && record?.businessStaff2?.firstName)
        return (
          <Flex align="center" gap="sm">
            <Avatar src={record?.businessStaff2?.avatar} />
            <RenderText text={renderName(record?.businessStaff2)} />
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Ангилал тохируулсан огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <RenderText text={record?.classificationSetDate ? dateTimeFormat(record.classificationSetDate) : "-"} />,
  },
  {
    title: "Бүс тохируулсан огноо, цаг",
    dataIndex: "name",
    width: "200px",
    render: (record) => <RenderText text={record?.areaSetDate ? dateTimeFormat(record.areaSetDate) : "-"} />,
  },
  {
    title: "Хуваарилсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.areaSetUser?.firstName ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.areaSetUser?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.areaSetUser)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
];

const useHeaderBuyerView = ({
  onClick,
  selected,
  businessStatus,
}: {
  onClick: (e: string, e1: any) => void;
  selected: any[];
  businessStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    renderTitle(record) {
      return (
        <Checkbox
          onChange={(e) => {
            if (!e.target.checked) {
              onClick("unselect_all", []);
            } else onClick("select_all", record?.rows);
          }}
          onClick={() => {}}
          checked={selected?.length > 0}
        />
      );
    },
    title: "",
    render: (record) => {
      return (
        <Checkbox
          onChange={() => {}}
          onClick={() => {
            onClick("select", record);
          }}
          checked={selected.some((item: any) => item.id === record.id)}
        />
      );
    },
  },
  {
    title: "ТТД",
    dataIndex: "name",
    width: "200px",
    render: (record) => <RenderText text={record?.regNumber ?? "-"} />,
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <Box>
          <RenderText text={record?.partner?.businessName ?? "-"} />
          <RenderText text={`#${record?.partner?.refCode ?? "-"}`} color="indigo" />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Box>
        <RenderText text={record?.profileName ?? "-"} />
        <RenderText text={`#${record?.refCode ?? "-"}`} />
      </Box>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      const branch = record?.branch;

      if (branch?.name) {
        return (
          <Box>
            <RenderText text={branch.name} />
            <RenderText text={`#${branch.refCode || "-"}`} color="indigo" />
          </Box>
        );
      }

      return "-";
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    width: "200px",
    render: (record) =>
      record?.classificationCategory ? (
        <Box>
          <RenderText text={record?.classificationCategory?.name ?? "-"} />
          <RenderText text={`#${record?.classificationCategory?.refCode ?? "-"}`} color="indigo" />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Статус",
    dataIndex: "name",
    width: "200px",
    render: (record) => (
      <Badge
        color={businessStatus.find((item: IReference) => item.code === record.businessStatus)?.color ?? "indigo"}
        variant="light"
        styles={{ label: { overflow: "visible", textOverflow: "unset" } }}>
        {businessStatus.find((item: IReference) => item.code === record.businessStatus)?.name ?? "-"}
      </Badge>
    ),
  },
  {
    title: "Тайлбар",
    dataIndex: "name",
    width: "200px",
    render: (record) => <PopoverText>{record?.areaDesc ?? "-"}</PopoverText>,
  },
  {
    title: "Хариуцсан ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaff ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.businessStaff?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.businessStaff)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Орлох ажилтан",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      if (record?.businessStaff2 && record?.businessStaff2?.firstName)
        return (
          <Flex align="center" gap="sm">
            <Avatar src={record?.businessStaff2?.avatar}>
              <IconPhoto />
            </Avatar>
            <RenderText text={renderName(record?.businessStaff2)} />
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Тохиргоо хийсэн",
    dataIndex: "name",
    width: "200px",
    render: (record) => {
      return record?.businessStaffSetUser?.firstName ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.businessStaffSetUser?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.businessStaffSetUser)} />
        </Flex>
      ) : (
        "-"
      );
    },
  },
];
