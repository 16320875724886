import { ActionIcon, Avatar, Box, Button, Checkbox, Flex, Modal, Space } from "@mantine/core";
import { IconEdit, IconPhoto } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { NetworkReferenceApi, PaymentTermApi } from "../../apis";
import { IPaymentTermItem } from "../../interfaces/IPaymentTermItem";
import { IReference } from "../../models/General";
import { message } from "../../utils/Message";
import { renderName } from "../../utils/render-name";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";
import { DirectoryTermForm } from "./directory-term-form";

export function DirectoryInvoiceTermList() {
  const { paymentTermConfigTypes = [], paymentTermConditions = [] } = useSelector((state: any) => state.general);
  const [action, setAction] = useState<any>([]);
  const NET_PT = usePermission("NET_PT");

  const params = useParams();
  const [data, setData] = useState<any>();
  const refTable = useRef<any>(null);
  const columns = useHeader({
    isEdit: NET_PT.isEdit,
    paymentTermConfigTypes,
    paymentTermConditions,
    onClick: async (key, record) => {
      let res = await PaymentTermApi.get(record.id ?? "");
      setAction([key, res]);
    },
  });
  const [filters] = React.useState({ query: "" });

  const getData = async (id: string) => {
    try {
      let res = await NetworkReferenceApi.get(id);
      setData(res);
    } catch (error: any) {
      message.error(error.message ?? "");
    }
  };

  useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, [params]);

  return (
    <PageLayout
      title={data?.name ?? "-"}
      subTitle={data?.description ?? "-"}
      breadcrumb={breadcrumbs.concat([{ label: data?.name ?? "", to: "" }])}
      extra={[
        <Button onClick={() => setAction(["term_add"])} key={0} hidden={!NET_PT.isCreate}>
          Нөхцөл нэмэх
        </Button>,
      ]}>
      <Space h={"xs"} />
      {NET_PT.isView ? (
        <Table ref={refTable} name="payment.termApi.list" columns={columns} filters={filters} loadData={(data) => PaymentTermApi.list(data!)} />
      ) : (
        NET_PT.isCreate
      )}

      <Modal withCloseButton={false} opened={action[0] === "term_add"} onClose={() => setAction([])} size={"xl"} centered>
        <DirectoryTermForm
          reload={async () => {
            await refTable.current.reload();
          }}
          action={action}
          setAction={setAction}
          subTitle="Төлбөрийн нөхцөл нэмэх"
          title="Төлбөрийн нөхцөл нэмэх"
        />
      </Modal>
      <Modal withCloseButton={false} opened={action[0] === "edit"} onClose={() => setAction([])} size={"xl"} centered>
        <DirectoryTermForm
          reload={async () => {
            refTable.current.reload();
          }}
          action={action}
          setAction={setAction}
          subTitle="Төлбөрийн нөхцөл засах"
          title="Төлбөрийн нөхцөл засах"
        />
      </Modal>
    </PageLayout>
  );
}

const breadcrumbs = [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Network удирдлага",
  },
  {
    label: "Лавлах мэдээлэл",
    to: "/directory",
  },
];

const useHeader = ({
  onClick,
  paymentTermConditions,
  isEdit,
}: {
  onClick: (key: string, record: IPaymentTermItem) => void;
  paymentTermConfigTypes: IReference[];
  paymentTermConditions: IReference[];
  isEdit: boolean;
}): ColumnType<IPaymentTermItem>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    align: "right",
    width: "100px",
    render: (record) => {
      return (
        <ActionIcon onClick={() => onClick("edit", record)} color={"indigo"} variant="light" disabled={!isEdit}>
          <IconEdit size={18} stroke={2} />
        </ActionIcon>
      );
    },
  },
  {
    title: "Нөхцөл",
    dataIndex: "condition",
    width: "200px",
    render: (record) => <RenderText text={paymentTermConditions.find((f: any) => f.code === record.condition)?.name ?? "-"} />,
  },
  {
    title: "Код",
    width: "200px",
    dataIndex: "refCode",
    render: (record) => <RenderText text={record?.refCode ?? "-"} />,
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    width: "420px",
    render: (record) => <PopoverText>{record?.description ?? "-"}</PopoverText>,
  },
  {
    title: "Хоног",
    width: "100px",
    dataIndex: "expireDayCount",
    render: (record) => <RenderText text={record?.expireDayCount ?? "-"} />,
  },
  {
    title: "Сар",
    width: "100px",
    dataIndex: "month",
    render: (record) => <RenderText text={record?.month ?? "-"} />,
  },
  {
    title: "Өдөр",
    dataIndex: "paymentDay",
    render: (record) => <RenderText text={record?.paymentDay ?? "-"} />,
  },
  {
    title: "Статус",
    dataIndex: "isActive",
    width: "120px",
    render: (record) => (
      <Flex gap="xs">
        <Checkbox checked={record?.isActive} onChange={() => {}} />
        {record?.isActive ? "Идэвхтэй" : "Идэвхгүй"}
      </Flex>
    ),
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "modifiedUser",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={"/"} radius="xl">
          <IconPhoto />
        </Avatar>
        <Box>
          <RenderText text={renderName(record?.modifiedUser)} />
          <RenderText text={record?.modifiedUser?.email} color="dimmed" />
        </Box>
      </Flex>
    ),
  },
  {
    title: "Default эсэх",
    dataIndex: "isMain",
    render: (record) => (
      <Flex gap="xs">
        <Checkbox checked={record?.isMain} onChange={() => {}} />
        {record?.isMain ? "Тийм" : "Үгүй"}
      </Flex>
    ),
  },
  {
    title: "DeHUB стандарт",
    dataIndex: "isOpen",
    render: (record) => (
      <Flex gap="xs">
        <Checkbox checked={record?.isOpen} onChange={() => {}} />
        {record?.isOpen ? "Тийм" : "Үгүй"}
      </Flex>
    ),
  },
];
