import { LoadingOverlay, Paper } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { DirectoryInvoiceTermList } from "./components/directory/directory-invoice-term-list";
import { Layout } from "./components/layout";
import { usePermission } from "./components/permission";
import { onMessageListener, requestForToken } from "./firebase/fcm-service";
import { IAuth } from "./interfaces/IAuth";
import { Access } from "./pages/access";
import { AccountManage } from "./pages/account-manage";
import { Dashboard } from "./pages/dashboard/dashboard";
import { DirectoryList } from "./pages/directory/list";
import { DistributionArea } from "./pages/distribution-area/list";
import { InvitionCameList } from "./pages/invition-came/list";
import { InvitionSend } from "./pages/invition-sent/Invition-send";
import { InvitionSentList } from "./pages/invition-sent/list";
import { NotFound } from "./pages/not-found";
import { PaymentTermManage } from "./pages/nw-term-payment";
import { OurBuyerList } from "./pages/our-buyer/list";
import { OurSupplierList } from "./pages/our-supplier/list";
import { Rank } from "./pages/rank/list";
import { ReferenceType } from "./pages/reference";
import { ReferencesList } from "./pages/reference/list";
import { Test } from "./pages/test";
import { authMe } from "./store/Auth";
import { initGeneral } from "./store/General";
import { message } from "./utils/Message";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { accessToken, user } = useSelector((state: { auth: IAuth }) => state.auth);
  const NET_DASH = usePermission("NET_DASH");

  const hasRequestedToken = useRef(false);

  // Firebase
  useEffect(() => {
    if (!hasRequestedToken.current) {
      requestForToken();
      hasRequestedToken.current = true;
    }

    onMessageListener((payload: any) => {
      showNotification({
        id: `notif-${Math.random()}`,
        title: payload?.notification?.title || "New Notification",
        message: payload?.notification?.body || "You have received a new notification.",
        color: "blue",
        autoClose: 5000,
      });
    });
  }, []);

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken) {
          const auth = await AuthApi.me();
          dispatch(authMe(auth));
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));
        } else __WINDOW_LOADED__ = false;
        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading && !__WINDOW_LOADED__) return <LoadingOverlay visible={loading} />;
  else
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={NET_DASH.hasAccess ? <Dashboard /> : <Paper p="sm">{NET_DASH.accessDenied()}</Paper>} />
          <Route path="/dashboard" element={NET_DASH.hasAccess ? <Dashboard /> : <Paper p="sm">{NET_DASH.accessDenied()}</Paper>} />

          <Route path="/came" element={<InvitionCameList />} />
          <Route path="/sent" element={<InvitionSentList />} />
          <Route path="/sent/send" element={<InvitionSend />} />

          {user?.currentBusiness?.type === "SUPPLIER" && <Route path="/our-buyers" element={<OurBuyerList />} />}
          {user?.currentBusiness?.type === "BUYER" && <Route path="/our-suppliers" element={<OurSupplierList />} />}

          <Route path="/nw-payment-term" element={<PaymentTermManage />} />
          <Route path="/account" element={<AccountManage />} />

          <Route path="/directory" element={<DirectoryList />} />
          <Route path="/directory/:id" element={<DirectoryInvoiceTermList />} />
          <Route path="/reference" element={<ReferencesList />} />
          <Route path="/reference/:type" element={<ReferenceType />} />

          <Route path="/rank" element={<Rank />} />

          <Route path="/distribution-area" element={<DistributionArea />} />

          <Route path="/access" element={<Access />} />
          <Route path="/test" element={<Test />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    );
}

export default App;
