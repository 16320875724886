import { ActionIcon, Avatar, Badge, Box, Flex, SegmentedControl, Space, Tooltip } from "@mantine/core";
import { IconFileText, IconMailFast, IconMailForward, IconPhoto } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { NetworkInvitationApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IInvitationSenderItem } from "../../interfaces/IInvitationSenderItem";
import { IReference } from "../../models/General";
import { dateTimeFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { Tag } from "../tags";
import RenderText from "../ui/render-text";

export function InvitedSupplierList({
  setAction,
  refTable,
  filters,
  setFilters,
}: {
  setAction: (e: any) => void;
  refTable: any;
  setFilters: (e: any) => void;
  filters: any;
}) {
  const { invitationStatus, invitationTypes = [] } = useSelector((state: any) => state.general);

  const columns = useHeader({
    invitationStatus,
    invitationTypes,
    onClick: async (key, record) => {
      let res = await NetworkInvitationApi.get(record.id ?? "");
      setAction([key, res]);
    },
  });

  return (
    <div>
      <SegmentedControl
        key={1}
        onChange={(e) => setFilters({ ...filters, status: e })}
        value={filters.status}
        data={[{ name: "Бүгд", code: "" }, ...(invitationStatus ?? [])].map((item: IReference, index: number) => {
          return {
            value: item.code,
            label: (
              <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
                {index !== 0 && <Box style={{ width: 8, height: 8, borderRadius: "100%", background: `${item?.color}` }} />}
                {item?.name ?? "-"}
              </Box>
            ),
          };
        })}
      />
      <Space h="md" />
      <Table ref={refTable} name="network.invitation.sent.supplier" columns={columns} filters={filters} loadData={(data) => NetworkInvitationApi.sent(data!)} />
    </div>
  );
}

const useHeader = ({
  onClick,
  invitationStatus,
  invitationTypes,
}: {
  onClick: (key: string, record: IInvitationSenderItem) => void;
  invitationStatus: IReference[];
  invitationTypes: IReference[];
}): ColumnType<IInvitationSenderItem>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    align: "center",
    render: (record) => {
      return (
        <Flex align="center" gap="sm">
          <Tooltip label="Дэлгэрэнгүй">
            <ActionIcon onClick={() => onClick("details", record)} color={"indigo"} variant="light">
              <IconFileText size={18} />
            </ActionIcon>
          </Tooltip>
          {record.invitationStatus === "DRAFT" && (
            <Tooltip label="Урих">
              <ActionIcon onClick={() => onClick("draft_invite", record)} color={"indigo"} variant="light" disabled={record?.invitationStatus !== "DRAFT"}>
                {record?.invitationStatus === "DRAFT" ? <IconMailForward size={18} stroke={2} /> : <IconMailFast size={18} stroke={2} />}
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Урилга",
    dataIndex: "refCode",
    render: (record) => <RenderText text={record?.refCode ?? "-"} />,
  },
  {
    title: "Урилга статус",
    dataIndex: "invitationStatus",
    render: (record) =>
      Tag(
        invitationStatus.find((item: any) => item.code === record.invitationStatus)?.color ?? "gray",
        invitationStatus.find((item: any) => item.code === record.invitationStatus)?.name ?? "-",
      ),
  },
  {
    title: "Огноо, цаг",
    dataIndex: "invitedDate",
    render: (record) => <RenderText text={record?.invitedDate ? dateTimeFormat(record?.invitedDate) : "-"} />,
  },
  {
    title: "Партнер нэр",
    dataIndex: "receiver.partnerName",
    render: (record) =>
      record?.receiver?.partner ? (
        <Box>
          <RenderText text={record?.receiver?.partner?.businessName ?? "-"} />
          <RenderText text={`#${record?.receiver?.partner?.refCode ?? "-"}`} />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Supplier нэр",
    dataIndex: "receiver.profileName",
    render: (record) =>
      record?.receiver ? (
        <Box>
          <RenderText text={record?.receiver?.profileName ?? "-"} />
          <RenderText text={`#${record?.receiver?.refCode ?? "-"}`} />
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Supplier админ",
    dataIndex: "receiver.staff.lastName",
    render: (record) =>
      record?.receiver?.staff ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.receiver?.staff?.avatar}>
            <IconPhoto />
          </Avatar>
          <RenderText text={renderName(record?.receiver?.staff)} />
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "И-мэйл хаяг",
    dataIndex: "receiver.staff.email",
    render: (record) => <RenderText text={record?.receiver?.staff?.email ?? "-"} />,
  },
  {
    title: "Утасны дугаар",
    dataIndex: "receiver.staff.phone",
    render: (record) => <RenderText text={record?.receiver?.staff?.phone ?? "-"} />,
  },
  {
    title: "Buyer роль",
    render: (record) => (
      <Badge variant="light" color={record?.sender?.type === "BUYER" ? "green" : "gray"}>
        {record?.sender?.type === "BUYER" ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Supplier роль",
    render: (record) => (
      <Badge variant="light" color={record?.sender?.type === "SUPPLIER" ? "green" : "gray"}>
        {record?.sender?.type === "SUPPLIER" ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Урилга хүлээн авсан бизнес",
    dataIndex: "receiver.profileName",
    render: (record) =>
      record?.receiver?.refCode ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.receiver?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={record?.receiver?.profileName ?? "-"} />
            <RenderText text={`#${record?.receiver?.refCode ?? "-"}`} />
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (record) => <RenderText text={invitationTypes?.find((item: any) => item.code === record.type)?.name ?? "-"} />,
  },
];
